<template>
  <div class="skipPage"></div>
</template>
<script>
export default {
  components: {},
  data() {
    return {};
  },
  created() {
    let path = this.$route.query.path;
    //switchTab、reLaunch、redirectTo、navigateTo、navigateBack
    let jumpType = this.$route.query.jumpType || "redirectTo";
    let url = "/" + decodeURIComponent(path);
    console.log("url:", url);
    console.log("switchTab:", jumpType);
    switch (jumpType) {
      case "switchTab":
        wx.miniProgram.switchTab({
          url: url,
        });
        break;
      case "reLaunch":
        wx.miniProgram.reLaunch({
          url: url,
        });
        break;
      case "redirectTo":
        wx.miniProgram.redirectTo({
          url: url,
        });
        break;
      case "navigateTo":
        let ua = navigator.userAgent.toLowerCase();
        var _this = this;
        if (ua.match(/MicroMessenger/i) == "micromessenger") {
          wx.miniProgram.getEnv(function(res) {
            console.log(res, "res");
            console.log(res.miniprogram); // true
            //小程序环境
            if (res.miniprogram) {
              wx.miniProgram.navigateTo({
                url: url,
                complete: () => {
                  setTimeout(() => {
                    window.history.go(-1);
                  }, 1000);
                },
              });
            } else {
              let urlArr = url.split("?");
              //获取 schemel链接地址
              _this.$axios
                .get(
                  `/ms-pocket-hospital/v1/live/getScheme/live?path=${encodeURIComponent(
                    urlArr[0]
                  )}&params=${encodeURIComponent(urlArr[1])}`,
                  {
                    basurlType: "useslhospital",
                  }
                )
                .then((res) => {
                  console.log(res.data.results, "jiekoucanshu");
                  setTimeout(() => {
                    window.history.go(-1);
                  }, 1000);
                  window.location.href = res.data.results;
                });
            }
          });
        } else {
          let urlArr = url.split("?");
          //获取 schemel链接地址
          this.$axios
            .get(
              `/ms-pocket-hospital/v1/live/getScheme/live?path=${encodeURIComponent(
                urlArr[0]
              )}&params=${encodeURIComponent(urlArr[1])}`,
              {
                basurlType: "useslhospital",
              }
            )
            .then((res) => {
              console.log(res.data.results, "jiekoucanshu");
              setTimeout(() => {
                window.history.go(-1);
              }, 1000);
              window.location.href = res.data.results;
            });
        }

        break;
      case "navigateBack":
        wx.miniProgram.navigateBack();
        break;
      default:
        wx.miniProgram.switchTab({
          url: "/pages/home/home",
        });
        break;
    }
  },
  mounted() {},
  methods: {},
  beforeRouteEnter(to, from, next) {
    console.log(to, from, next, "luyou");
    next();
  },
};
</script>

<style lang="scss"></style>
